import Cookies from 'js-cookie'

const env = process.env.VUE_APP_ENV
const domain = env === 'development' ? window.location.hostname : 'sourceaix.com'
const tokenKey = `syws-authorization-${env[0]}`
const usersKey = `syws-users-${env[0]}`
const tabsKey = `syws-tabs-${env[0]}`

export function getTokenCookie() {
    return Cookies.get(tokenKey)
}

export function setTokenCookie(data, expiresTime = 60 * 60 * 24 * 1) {
    // expiresTime 单位/秒
    let expires = new Date(new Date() * 1 + expiresTime * 1000)
    return Cookies.set(tokenKey, data, { domain, expires: expires }) // 1天有效
}

export function removeTokenCookie() {
    return Cookies.remove(tokenKey, { domain })
}

export function getUsersCookie() {
    return Cookies.get(usersKey)
}

export function setUsersCookie(data) {
    return Cookies.set(usersKey, data, { domain, expires: 1 }) // 1天有效
}

export function removeUsersCookie() {
    return Cookies.remove(usersKey, { domain })
}

export function getTabsCookie() {
    return Cookies.get(tabsKey)
}

export function setTabsCookie(data) {
    return Cookies.set(tabsKey, data, { domain })
}

export function removeTabsCookie() {
    return Cookies.remove(tabsKey, { domain })
}
