import { defineStore } from 'pinia'

// 系统全局状态 持久化存储sessionStorage
export const useAppStore = defineStore('app', {
    state: () => ({
        tabs: [],
        menus: [],
    }),
    getters: {
        getTabs() {
            return this.tabs
        },
        getMenus() {
            return this.meuns
        },
    },
    actions: {
        setTabs(val) {
            this.tabs = val
        },
        setMenus(val) {
            this.menus = val
        },
    },
    persist: {
        enabled: true, // 是否开启本地sessionStorage数据缓存
    },
})