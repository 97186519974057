const map = {
    "//localhost:8888/": "//scc.sourceaix.com/", // 中控主应用
    "//localhost:8000/": "//sfl.sourceaix.com/", // 产品特征库
    "//localhost:8100/": "//sre.sourceaix.com/", // 调研评测任务管理系统
    "//localhost:8200/": "//sbc.sourceaix.com/", // 基础属性配置系统
    "//localhost:8300/": "//scs.sourceaix.com/", // AI智能客服管理系统
    "//localhost:8400/": "//sdc.sourceaix.com/", // AI分析大屏配置系统
    "//localhost:8500/": "//swp.sourceaix.com/", // 文章写作系统
}

const map2 = {
    "//localhost:8888/": "//t-scc.sourceaix.com/", // 中控主应用
    "//localhost:8000/": "//t-sfl.sourceaix.com/", // 产品特征库
    "//localhost:8100/": "//t-sre.sourceaix.com/", // 调研评测任务管理系统
    "//localhost:8200/": "//t-sbc.sourceaix.com/", // 基础属性配置系统
    "//localhost:8300/": "//t-scs.sourceaix.com/", // AI智能客服管理系统
    "//localhost:8400/": "//t-sdc.sourceaix.com/", // AI分析大屏配置系统
    "//localhost:8500/": "//t-swp.sourceaix.com/", // 文章写作系统
}

export default function hostMap(host) {
    // eslint-disable-next-line no-constant-condition
    if (process.env.VUE_APP_ENV === "production") return map[host]
    if (process.env.VUE_APP_ENV === "stage") return map2[host]
    return host
}
