import wujieVue from 'wujie-vue2'
const lifecycles = {
    beforeLoad: (appWindow) => {
        console.log(`${appWindow.__WUJIE?.id} beforeLoad 生命周期 >>> ${new Date().getTime()}`)
    },
    beforeMount: (appWindow) => {
        console.log(`${appWindow.__WUJIE?.id} beforeMount 生命周期 >>> ${new Date().getTime()}`)
    },
    afterMount: (appWindow) => {
        console.log(`${appWindow.__WUJIE?.id} afterMount 生命周期 >>> ${new Date().getTime()}`)
    },
    beforeUnmount: (appWindow) => {
        console.log(`${appWindow.__WUJIE?.id} beforeUnmount 生命周期 >>> ${new Date().getTime()}`)
    },
    afterUnmount: (appWindow) => {
        console.log(`${appWindow.__WUJIE?.id} afterUnmount 生命周期 >>> ${new Date().getTime()}`)
    },
    activated: (appWindow) => {
        console.log(`${appWindow.__WUJIE?.id} activated 生命周期 >>> ${new Date().getTime()}`)
        wujieVue.bus.$emit('subAppActivated')
    },
    deactivated: (appWindow) => {
        console.log(`${appWindow.__WUJIE?.id} deactivated 生命周期 >>> ${new Date().getTime()}`)
    },
    loadError: (url, e) => {
        console.log(`${url} 加载失败 >>> ${new Date().getTime()}`, e)
    },
}

export default lifecycles
